@import './reset.css';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap');

:root {
	/* Colors */
	--blackpure: #000;
	--black: #171718;
	--black-border: #26292d;
	--white: #fff;
	--red: #e60000;

	/* Nav */
	--nav-bg: var(--black);
	--nav-border: var(--black-border);
	--nav-text: var(--white);

	/* Header */
	--header-bg: var(--black);
	--header-text: var(--white);

	/* Footer */
	--footer-bg: var(--black);
	--footer-text: var(--white);

	/* Light mode */
	--page-bg: var(--white);
	--text-color: var(--black);
	--accent: var(--red);
	--title-1: var(--accent);

	--project-card-bg: var(--white);
	--project-card-text: var(--black);
	--box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.25);
}

.dark {
	/* Dark mode */
	--page-bg: #252526;
	--text-color: var(--white);

	--title-1: var(--white);

	--project-card-bg: var(--black);
	--project-card-text: var(--white);
	--box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8);
}

html,
body {	
	min-height: 100vh;

	font-family: 'DM Sans', sans-serif;
	letter-spacing: -0.5px;

	/* Light mode */
	background-color: var(--page-bg);
	color: var(--text-color);
}

#root {min-height: 100vh;}

.App {min-height: 100vh;
	 display: flex;
	 flex-direction: column;
	}


/* General */

.container {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1200px;
}

.none {
	display: none !important;
}

.section {
	padding: 60px 0;
}

.title-1 {
	margin-bottom: 50px;

	font-size: 60px;
	font-weight: 700;
	line-height: 1.3;
	color: var(--title-1);
	text-align: center;
}

.title-2 {
	margin-bottom: 20px;
	
	font-size: 40px;
	font-weight: 700;
	line-height: 1.3;
}

.title-3 {
	margin: 20px auto 5px auto;
	color: var(--accent);
	font-size: 30px;
	font-weight: 700;
	line-height: 1;
}

/* Nav */

.nav {
	padding: 20px 0;
	background-color: var(--nav-bg);
	border-bottom: 1px solid var(--nav-border);
	color: var(--nav-text);
	letter-spacing: normal;
}

.nav-row {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	column-gap: 30px;
	row-gap: 20px;
	flex-wrap: wrap;
}

.logo {
	margin-right: auto;
	color: var(--nav-text);
	font-size: 24px;
	font-family: 'Poppins', sans-serif;
}

.logo strong {
	font-weight: 700;
}

.logo em {
	font-style: normal;
	color: var(--accent);
}

.nav-list {
	display: flex;
	flex-wrap: wrap;
	row-gap: 10px;
	align-items: center;
	column-gap: 40px;
	font-size: 16px;
	font-weight: 500;
	font-family: 'Poppins', sans-serif;
}

.nav-list__link {
	color: var(--nav-text);
	transition: opacity 0.2s ease-in;
}

.nav-list__link:hover {
	opacity: 0.8;
}

.nav-list__link--active {
	position: relative;
}

.nav-list__link--active::before {
	content: '';

	position: absolute;
	left: 0;
	top: 100%;

	display: block;
	height: 2px;
	width: 100%;
	background-color: var(--accent);
}



/* Header */

.header {
	padding: 40px 0;
	min-height: 65vh;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: var(--header-bg);
	background-image: linear-gradient(0deg, rgba(3, 3, 3, 0.664), rgba(14, 13, 13, 0.877)), url('./../img/header-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;


	color: var(--header-text);
	text-align: center;
}

.header__wrapper {
	padding: 0 15px;
	max-width: 860px;
}

.header__title {
	margin-bottom: 20px;
	font-size: 40px;
	font-weight: 700;
	line-height: 1.4;
}

.header__title strong {
	font-size: 60px;
	font-weight: 700;
}

.header__title em {
	font-style: normal;
	color: var(--accent);
}

.header__text {
	margin-bottom: 40px;
	font-size: 21px;
	line-height: 1.333;
}

.header__text p + p {
	margin-top: 0.5em;
}

/* Btn */

.btn {
	display: inline-block;
	
	height: 44px;
	padding: 10px 20px;
	margin: 10px 5px 0 23px;
	border-radius: 5px;
	background-color: var(--accent);
	color: var(--white);
	letter-spacing: 0.15px;
	font-size: 17px;
	font-weight: 500;

	transition: opacity 0.2s ease-in;
}

.btn:hover {
	opacity: 0.8;
}

.btn:active {
	position: relative;
	top: 1px;
}

/* Btn outline */

.btn-outline {
	display: flex;
	column-gap: 10px;
	align-items: center;

	height: 48px;
	padding: 12px 20px;
	
	border-radius: 5px;
	border: 1px solid #000;
	background-color: #fff;
	color: #000;

	transition: opacity 0.2s ease-in;
}

.btn-outline:hover {
	opacity: 0.8;
}

.btn-outline:active {
	position: relative;
	top: 1px;
}

.btn-placeholder {
	display: flex;
	justify-content: center;
	margin: 30px auto;
}

/* Projects  */

.projects {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	column-gap: 30px;
	row-gap: 30px;
	
}

@media (max-width: 1216px) {
	.projects {
	  justify-content: center;
	}
  }




/* Project details */

.project-details {
	margin: 0 auto;
	max-width: 700px;
	display: flex;
	flex-direction: column;
	/*align-items: center;*/
	text-align:justify;
}

.project-details__cover {
	max-width: 100%;
	min-width: 100%;
	margin-bottom: 40px;
	box-shadow: var(--box-shadow);
	border-radius: 10px;
}

.project-details__desc {
	margin-bottom: 10px;
	padding: 0 23px;	
	font-weight: 700;
	font-size: 24px;
	line-height: 1.5;
}

.project-details__text {
	
	padding: 0 23px;
	font-weight: 500;	
	font-size: 18px;
	line-height: 1.5;	
}

@media (max-width: 510px) {
	.project-details__text {
		font-size: 3.3vw;
	}
  }

.project-details__combo {
	display: flex;	
	justify-content:space-between;
	align-items: first baseline ;
	flex-wrap: wrap;
	max-width: 680px;	
}

/* Content list */

.content-list {
	margin: 0 auto;
	max-width:700px;
	display: flex;
	flex-direction: column;
	align-items: center;	
	text-align: justify;
}

.content-list a {
	color: var(--accent);
}

.content-list__item {
	font-size: 18px;
	line-height: 1.5;
}

.content-list__item p + p {
	margin-top: 0.5em;
}

.content-list em {
	font-style: normal;
	color: var(--accent);
}

/* Footer */

.footer {
	margin-top: auto;
	padding: 60px 0 50px;
	background-color: var(--footer-bg);
	color: var(--footer-text);
}

.footer__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 27px;
}

.social {
	display: flex;
	column-gap: 30px;
	align-items: center;
}

.copyright {
	font-size: 16px;
}

.copyright p + p {
	margin-top: 0.5em;
}

@media (max-width: 620px) {
	/* Header  */

	.header {
		min-height: unset;
	}

	.header__title {
		font-size: 30px;
	}

	.header__title strong {
		font-size: 40px;
	}

	/* Nav */

	.nav-row {
		justify-content: space-between;
	}

	.dark-mode-btn {
		order: 0;
	}

	/* General */

	.section {
		padding: 40px 0;
	}

	.title-1 {
		margin-bottom: 30px;
		font-size: 40px;
	}

	.title-2 {
		margin-bottom: 10px;
		font-size: 30px;
	}

	/* Projects */

	.project__title {
		font-size: 22px;
	}

	/* Project page */

	.project-details__desc {
		margin-bottom: 20px;
		font-size: 22px;
	}

	/* Content List */

	.content-list {
		row-gap: 20px;
	}

	.content-list__item {
		font-size: 16px;
	}

	/* Footer */

	.footer {
		padding: 40px 0 30px;
	}

	.footer__wrapper {
		row-gap: 20px;
	}

	/* Social */

	.social {
		column-gap: 20px;
	}

	.social__item {
		width: 28px;
	}


}
