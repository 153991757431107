
.embed {

width: 700px;
height: 550px;
max-width:100%;
margin-top: 10px;
background-image: url('../img/Loader.gif');
background-repeat: no-repeat;
background-position: center;

}


    